<template>
    <Page>
        <NounsNav />

        <h2>
            <Icon v="ghost" />
            <T>nouns.dukajNouns.header</T>
        </h2>

        <div class="d-flex flex-column flex-md-row">
            <div>
                <T>nouns.dukajNouns.info</T>
                <CensusStat type="nouns" item="dukatywy" colour="info" />
                <p><Share :title="$t('nouns.dukajNouns.header')" /></p>
            </div>
            <figure>
                <img src="/img-local/flags/dukaizmy.png" :alt="$t('nouns.dukajNouns.flag.alt')">
                <figcaption><T>nouns.dukajNouns.flag.caption</T></figcaption>
            </figure>
        </div>

        <details open class="border mb-3">
            <summary class="bg-light p-3">
                <h4 class="h5 d-inline">
                    <T>nouns.examples</T>
                </h4>
            </summary>
            <div class="border-top table-responsive">
                <table class="table table-striped table-hover table-fixed-3">
                    <thead>
                        <tr>
                            <th class="text-nowrap">
                                <Icon v="mars" />
                                <T>nouns.masculine</T>
                            </th>
                            <th class="text-nowrap">
                                <Icon v="venus" />
                                <T>nouns.feminine</T>
                            </th>
                            <th class="text-nowrap">
                                <Icon v="neuter" />
                                <T>nouns.dukajNouns.label</T>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="noun in dukajNouns" :key="noun.id">
                            <td>
                                <ul class="list-singular">
                                    <li v-for="w in noun.masc">
                                        {{ w }}
                                    </li>
                                </ul>
                                <ul class="list-plural">
                                    <li v-for="w in noun.mascPl">
                                        {{ w }}
                                    </li>
                                </ul>
                            </td>
                            <td>
                                <ul class="list-singular">
                                    <li v-for="w in noun.fem">
                                        {{ w }}
                                    </li>
                                </ul>
                                <ul class="list-plural">
                                    <li v-for="w in noun.femPl">
                                        {{ w }}
                                    </li>
                                </ul>
                            </td>
                            <td>
                                <ul class="list-singular">
                                    <li v-for="w in noun.neutr">
                                        <Declension :word="w" :template="noun.declension" />
                                    </li>
                                </ul>
                                <ul class="list-plural">
                                    <li v-for="w in noun.neutrPl">
                                        <Declension :word="w" plural :singular-options="noun.neutr" :template="noun.declension" />
                                    </li>
                                </ul>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </details>

        <details open class="border mb-3">
            <summary class="bg-light p-3">
                <h4 class="h5 d-inline">
                    <T>nouns.dukajNouns.extended.header</T>
                </h4>
            </summary>
            <div class="border-top">
                <div class="d-flex flex-column flex-md-row">
                    <div class="p-3">
                        <h5>⋅ <T>nouns.singular</T></h5>
                        <Declension word="" :template="dukajExtendedDeclension" open />
                    </div>
                    <div class="p-3">
                        <h5>⁖ <T>nouns.plural</T></h5>
                        <Declension word="" :template="dukajExtendedDeclension" open plural />
                    </div>
                </div>
            </div>
        </details>

        <details open class="border mb-3">
            <summary class="bg-light p-3">
                <h4 class="h5 d-inline">
                    Generator
                </h4>
            </summary>
            <div class="border-top p-3">
                <p>
                    Końcówki dukatywów są tak regularne, że zamiast pełnego słownika
                    powinien wystarczyć nawet automatyczny generator.
                </p>
                <p>
                    Wpisz poniżej dowolny rzeczownik określający osobę,
                    <strong>w rodzaju męskim liczby pojedynczej</strong>:
                </p>
                <div class="form-group">
                    <input v-model="generatorWord" class="form-control" placeholder="Wpisz rzeczownik w rodzaju męskim liczby pojedynczej">
                </div>
                <div v-if="generatorResult" class="table-responsive">
                    <table class="table table-fixed-3">
                        <thead>
                            <tr>
                                <th class="text-nowrap">
                                    <Icon v="mars" />
                                    <T>nouns.masculine</T>
                                </th>
                                <th class="text-nowrap">
                                    <Icon v="venus" />
                                    <T>nouns.feminine</T>
                                </th>
                                <th class="text-nowrap">
                                    <Icon v="neuter" />
                                    <T>nouns.dukajNouns.label</T>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <ul class="list-singular">
                                        <li v-for="w in generatorResult.masc">
                                            {{ w }}
                                        </li>
                                    </ul>
                                    <ul class="list-plural">
                                        <li v-for="w in generatorResult.mascPl">
                                            {{ w }}
                                        </li>
                                    </ul>
                                </td>
                                <td>
                                    <ul class="list-singular">
                                        <li v-for="w in generatorResult.fem">
                                            {{ w }}
                                        </li>
                                    </ul>
                                    <ul class="list-plural">
                                        <li v-for="w in generatorResult.femPl">
                                            {{ w }}
                                        </li>
                                    </ul>
                                </td>
                                <td>
                                    <ul class="list-singular">
                                        <li v-for="w in generatorResult.neutr">
                                            <Declension :word="w" :template="generatorResult.declension" />
                                        </li>
                                    </ul>
                                    <ul class="list-plural">
                                        <li v-for="w in generatorResult.neutrPl">
                                            <Declension :word="w" :template="generatorResult.declension" plural />
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div v-else class="alert alert-warning">
                    <p class="mb-0">
                        Niestety, podane słowo nie pasuje do żadnego naszego szablonu
                    </p>
                </div>
            </div>
        </details>

        <section v-if="sources && Object.keys(sources).length">
            <Literature :sources="sources" />
        </section>
    </Page>
</template>

<script>
import { useNuxtApp } from 'nuxt/app';

import NounsNav from './NounsNav.vue';
import templates from './dukatywy.tsv';

import useConfig from '~/composables/useConfig.ts';
import { Noun, NounDeclension, SourceLibrary } from '~/src/classes.ts';

const dukajDeclension = new NounDeclension({
    M: 'u',
    D: 'u',
    C: 'u',
    B: 'u',
    N: 'um',
    Msc: 'um',
    W: 'u',
    M_pl: 'y',
    D_pl: 'ych',
    C_pl: 'ym',
    B_pl: 'ych',
    N_pl: 'ymi',
    Msc_pl: 'ych',
    W_pl: 'y',
});
const dukajAdjectiveDeclension = new NounDeclension({
    M: 'u',
    D: 'enu',
    C: 'ewu',
    B: 'enu',
    N: 'um',
    Msc: 'um',
    W: 'u',
    M_pl: 'y',
    D_pl: 'ych',
    C_pl: 'ym',
    B_pl: 'ych',
    N_pl: 'ymi',
    Msc_pl: 'ych',
    W_pl: 'y',
});
const dukajIDeclension = new NounDeclension({
    M: 'u',
    D: 'u',
    C: 'u',
    B: 'u',
    N: 'um',
    Msc: 'um',
    W: 'u',
    M_pl: 'i',
    D_pl: 'ich',
    C_pl: 'im',
    B_pl: 'ich',
    N_pl: 'imi',
    Msc_pl: 'ich',
    W_pl: 'i',
});

export default {
    components: { NounsNav },
    setup() {
        const { $translator: translator } = useNuxtApp();
        useSimpleHead({
            title: translator.translate('nouns.dukajNouns.header'),
            banner: 'img-local/flags/dukaizmy.jpg',
            description: translator.translate('nouns.dukajNouns.info')[0],
        }, translator);
        return {
            config: useConfig(),
        };
    },
    data() {
        return {
            dukajNouns: [
                new Noun(this.config, {
                    id: 'astronauta',
                    masc: 'astronauta',
                    fem: 'astronautka',
                    neutr: 'astronautu',
                    mascPl: 'astronauci',
                    femPl: 'astronautki',
                    neutrPl: 'astronauty',
                    declension: dukajDeclension,
                }),
                new Noun(this.config, {
                    id: 'przyjaciel',
                    masc: 'przyjaciel',
                    fem: 'przyjaciółka',
                    neutr: 'przyjaciołu',
                    mascPl: 'przyjaciele',
                    femPl: 'przyjaciółki',
                    neutrPl: 'przyjacioły',
                    declension: dukajDeclension,
                }),
                new Noun(this.config, {
                    id: 'twórca',
                    masc: 'twórca',
                    fem: 'twórczyni',
                    neutr: 'twórcu',
                    mascPl: 'twórcy',
                    femPl: 'twórczynie',
                    neutrPl: 'twórcy',
                    declension: dukajDeclension,
                }),
                new Noun(this.config, {
                    id: 'radny',
                    masc: 'radny',
                    fem: 'radna',
                    neutr: 'radnu',
                    mascPl: 'radni',
                    femPl: 'radne',
                    neutrPl: 'radny',
                    declension: dukajAdjectiveDeclension,
                }),
                new Noun(this.config, {
                    id: 'jegomość',
                    masc: 'jegomość',
                    fem: 'jejmość',
                    neutr: 'jenumość',
                    mascPl: 'ichmoście',
                    femPl: 'ichmoście',
                    neutrPl: 'ichmoście',
                    // declension: new NounDeclension({
                    //     M: 'mość', D: 'mościa', C: 'mościowi', B: 'mościa', N: 'mościem', Msc: 'mościem', W: 'mościu',
                    //     M_pl: 'moście', D_pl: 'mościów', C_pl: 'mościom', B_pl: 'mościów', N_pl: 'mościami', Msc_pl: 'mościami', W_pl: 'moście',
                    // }),
                    declension: new NounDeclension({
                        M: 'mość',
                        D: 'mościu',
                        C: 'mościu',
                        B: 'mościu',
                        N: 'mościum',
                        Msc: 'mościum',
                        W: 'mościu',
                        M_pl: 'moście',
                        D_pl: 'mościch',
                        C_pl: 'mościm',
                        B_pl: 'mościm',
                        N_pl: 'mościch',
                        Msc_pl: 'mościmi',
                        W_pl: 'moście',
                    }),
                }),
            ],
            dukajDeclension,
            dukajAdjectiveDeclension,
            dukajExtendedDeclension: new NounDeclension({
                M: 'tenu kosmicznu twórcu',
                D: 'tenu kosmicznenu twórcu',
                C: 'tewu kosmicznewu twórcu',
                B: 'tenu kosmicznenu twórcu',
                N: 'tum kosmicznum twórcum',
                Msc: 'tum kosmicznum twórcum',
                W: 'ty kosmicznu twórcu',

                M_pl: 'teny kosmiczny twórcy',
                D_pl: 'tych kosmicznych twórcych',
                C_pl: 'tym kosmicznym twórcym',
                B_pl: 'tych kosmicznych twórcych',
                N_pl: 'tymi kosmicznymi twórcymi',
                Msc_pl: 'tych kosmicznych twórcych',
                W_pl: 'wy kosmiczny twórcy',
            }),
            sources: undefined,
            templates,
            generatorWord: 'fotograf',
        };
    },
    computed: {
        template() {
            let longestMatch = 0;
            let matchingTemplates = [];
            for (const t of templates) {
                if (!this.generatorWord.endsWith(t.masc)) {
                    continue;
                }
                if (t.masc.length > longestMatch) {
                    longestMatch = t.masc.length;
                    matchingTemplates = [t];
                } else if (t.masc.length === longestMatch) {
                    matchingTemplates.push(t);
                }
            }

            if (!matchingTemplates.length) {
                return null;
            }

            return matchingTemplates[0];
        },
        generatorResult() {
            if (!this.template) {
                return null;
            }

            const root = this.generatorWord.substring(0, this.generatorWord.length - this.template.masc.length);

            const result = { id: null };
            for (const k in this.template) {
                if (!this.template.hasOwnProperty(k)) {
                    continue;
                }
                result[k] = this.template[k].split('/').map((ending) => root + ending)
                    .join(' / ');
            }

            result.declension = this.generatorWord.endsWith('y') || this.generatorWord.endsWith('i')
                ? dukajAdjectiveDeclension
                : dukajDeclension;

            if (this.template.neutrPl.endsWith('i')) {
                result.declension = dukajIDeclension;
            }

            return new Noun(this.config, result);
        },
    },
    async mounted() {
        const rawSources = await $fetch('/api/sources?pronoun=dukatywy');
        this.sources = {
            '': new SourceLibrary(this.config, rawSources).getForPronoun('dukatywy'),
        };
    },
};
</script>

<style lang="scss" scoped>
    @import "assets/variables";

    figure {
        width: 100%;
        max-width: 24rem;
        padding: $spacer;
        img {
            width: 100%;
        }
        figcaption {
            font-size: $small-font-size;
        }
    }
    @include media-breakpoint-up('md') {
        figure {
            min-width: 20rem;
        }
    }
</style>
